import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter,
    RouterProvider,
    Route, } from "react-router-dom";
import Media from "./components/media/Media";
import ContactMe from "./components/contact-me/ContactMe";
import Resources from "./components/Resources/Resources";
import AboutMePage from "./components/aboutMePage/AboutMePage";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/WorkingTogether",
        element: <div>Working together</div>,
    },
    {
        path: "/AboutMePage",
        element: <AboutMePage/>
    },
    {
        path: "/Media",
        element: <Media/>
    },
    {
        path: "/Resources",
        element: <Resources/>
    },
    {
        path: "/Contact",
        element: <ContactMe/>
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
