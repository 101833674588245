import './bannerOfTopics.css'
import leafLeft from "../../assets/images/leaf-left.png"
import leafRight from "../../assets/images/leaf-right.png"
import { motion } from 'framer-motion'

const BannerOfTopics = () => {

    const pageTitleAnimation = {
        offscreen: {
            y: '8vh',
            opacity: 0,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.3 },
        },
    }

    const pageTitleAnimationTwo = {
        offscreen: {
            y: '8vh',
            opacity: 0,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.6 },
        },
    }

    const pageTitleAnimationThree = {
        offscreen: {
            y: '8vh',
            opacity: 0,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.9 },
        },
    }

    return (
        <div className='banner'>
            <ul className='banner-topics'>
                <motion.li
                    initial={'offscreen'}
                    whileInView={'onscreen'}
                    transition={{ y: { ease: 'easeIn' } }}
                    viewport={{ once: true }}
                    variants={pageTitleAnimation}
                >Consulting</motion.li>
                <li className='leaf'>
                    <img className="leaf-left" src={leafLeft}
                         alt=""/>
                </li>
                <motion.li
                    initial={'offscreen'}
                    whileInView={'onscreen'}
                    transition={{ y: { ease: 'easeIn' } }}
                    viewport={{ once: true }}
                    variants={pageTitleAnimationTwo}
                >Coaching</motion.li>
                <li className='leaf'>
                    <img className="leaf-right" src={leafRight}
                         alt=""/>
                </li>
                <motion.li
                    initial={'offscreen'}
                    whileInView={'onscreen'}
                    transition={{ y: { ease: 'easeIn' } }}
                    viewport={{ once: true }}
                    variants={pageTitleAnimationThree}
                >Educating</motion.li>
            </ul>
        </div>
    )
}

export default BannerOfTopics;

