import './aboutMe.css'
import aboutMeImage from "../../assets/images/about-me-image.png";
import {Link} from "react-router-dom";

const AboutMe = () => {

    return (
        <div id="about-me" className='about-me'>
            <img className="about-me-image" src={aboutMeImage}
                 alt=""/>
            <div className='about-me-text-container'>
                <div className='about-me-title'>
                    About Me
                </div>
                <div className='about-me-body'>
                    For nearly twenty years, I was a state-licensed psychotherapist and owner of an independent
                    practice in Arizona, with specialty training and advanced certifications in trauma treatment
                    therapies, including eye movement desensitization and reprocessing (EMDR). Along with my work
                    in private practice, I served on staff at the 2012 launch of the University of Arizona Integrative
                    Health Center in Phoenix, associated with Dr. Andrew Weil and the Arizona Center for Integrative
                    Medicine, where I conducted an unpublished, retrospective study on the treatment effects of EMDR
                    therapy on co-occurring anxiety and chronic medical conditions. Since 2018, I served on an EMDRIA
                    Board task force charged with drafting guidelines for the delivery of EMDR therapy virtually as
                    well as training therapists in EMDR therapy through distance learning. In 2022, that initiative
                    developed into the inaugural EMDRIA Training Council, upon which I served as a member of the
                    steering committee.
                    <br/><br/>
                    Prior to my career in psychotherapy, as an MBA, I spent 25 years in the corporate sector;
                    in the later years of that chapter...
                </div>
                <Link to="AboutMePage">
                    <div className='button'>
                        <button className='learn-more-about-btn'>
                            Read More
                        </button>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default AboutMe;
