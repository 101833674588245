import './imageSection.css'
import imageSectionImg from "../../assets/images/library-image.png";

const ImageSection = () => {
    return (
        <div>
            <img className="image-section-img" src={imageSectionImg}
                 alt=""/>
        </div>
    )
}

export default ImageSection;
