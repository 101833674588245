import './media.css'
import Header from "../header/Header";
import video1 from "../../assets/images/video-1.png";
import video2 from "../../assets/images/video-2.png";
import mediaImage from "../../assets/images/media-image.png";
import pathImage from "../../assets/images/path-image.jpg";
import Footer from "../footer/Footer";
import ImageSection from "../imageSection/ImageSection";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import React from "react";

const Media = () => {
    return (
        <div>
            <ScrollToTop />
            <Header/>
            <div className='media-blocks'>
                <div className='therapy-professionals-text-container'>
                    <div className='therapy-professionals-title'>
                        Publications & Media
                    </div>
                    <div className='topic-sections'>
                        <div className='topic-sections-column'>
                            <div className='topic-sections-row'>
                                <div className='consulting-section'>
                                    <img className="image-block" src={video1}
                                         alt=""/>
                                    <div className='section-text'>
                                        <div className='section-title'>
                                            Media - Video
                                        </div>
                                        <div className='section-body'>
                                            YouTube: Gayle Cordes LPC, DBH, LISAC | PCS Referring Therapist
                                        </div>
                                        <div className='button'>
                                            <a href={"https://www.youtube.com/watch?v=Vhhe_WWuvio"}>
                                                <button className='learn-more-btn'>
                                                    Watch On Youtube
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='coaching-section'>
                                    <img className="image-block" src={video2}
                                         alt=""/>
                                    <div className='section-text'>
                                        <div className='section-title'>
                                            Media - Video
                                        </div>
                                        <div className='section-body'>
                                            YouTube: Dr. Gayle Cordes, DBH | Integrated Care, EDMR, Trauma Informed Care | Doctor of Behavioral Health
                                        </div>
                                        <div className='button'>
                                            <a href={"https://www.youtube.com/watch?v=eJBwkRus6Zw"}>
                                                <button className='learn-more-btn'>
                                                    Watch On Youtube
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='therapy-professionals-title'>
                        Guidelines for Virtual EMDR Therapy
                    </div>
                    <div className='therapy-professionals-body'>
                        Ground breaking guidelines for the delivery of EMDR therapy developed and published February 2020.
                    </div>
                    <a href="https://www.emdria.org/wp-content/uploads/2020/04/virtual_tg_report_for_member.pdf">
                        <div className='button'>
                            <button className='learn-more-about-btn'>
                                Read Paper
                            </button>
                        </div>
                    </a>
                </div>
                <div className='image-side-panel'>
                    <img className="path-image" src={pathImage}
                         alt=""/>
                </div>
            </div>
            <ImageSection/>
            <Footer/>
        </div>
    )
}

export default Media;
