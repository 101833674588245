import './App.css';
import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";
import BannerOfTopics from "./components/bannerOfTopics/BannerOfTopics";
import Consultant from "./components/consultant/Consultant";
import TherapyProfessionals from "./components/therapyProfessionals/TherapyProfessionals";
import AboutMe from "./components/aboutMe/AboutMe";
import SignUp from "./components/signUp/SignUp";
import Footer from "./components/footer/Footer";
import Blocks from "./components/blocks/Blocks";
import Testimonials from "./components/testimonials/Testimonials";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import React from "react";

function App() {
  return (
      <div className="App">
          <ScrollToTop />
          <Header/>
          <Hero/>
          <Consultant/>
          <BannerOfTopics/>
          <TherapyProfessionals/>
          <Testimonials/>
          <Blocks/>
          <AboutMe/>
          <Footer/>
      </div>
  );
}

export default App;
