import './blocks.css'
import bookSidePanel from "../../assets/images/book-side-panel.png";
import consultingBlock from "../../assets/images/consulting-block.png";
import coachingBlock from "../../assets/images/coaching-block.png";
import graduateBlock from "../../assets/images/graduate-block.png";
import publicationsBlock from "../../assets/images/publications-block.png";
import {Link} from "react-router-dom";
import { motion } from 'framer-motion'

const Blocks = () => {

    const pageTitleAnimation = {
        offscreen: {
            y: '20vh',
            opacity: 0,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.9 },
        },
    }

    return (
        <div className='blocks'>
            <div className='therapy-professionals-text-container'>
                <div className='therapy-professionals-title'>
                    Learn More
                </div>
                <div className='topic-sections'>
                    <div className='topic-sections-column'>
                        <div className='topic-sections-row'>
                            <motion.div
                                initial={'offscreen'}
                                whileInView={'onscreen'}
                                transition={{ y: { ease: 'easeIn' } }}
                                viewport={{ once: true }}
                                variants={pageTitleAnimation}
                                className='consulting-section'>
                                <img className="image-block" src={consultingBlock}
                                     alt=""/>
                                <div className='section-text'>
                                    <div className='section-title'>
                                        Media
                                    </div>
                                    <div className='section-body'>
                                        Watch interviews about my profession on YouTube
                                    </div>
                                    <Link to="Media">
                                        <div className='button-blocks'>
                                            <button className='learn-more-btn'>
                                                Learn More
                                            </button>
                                        </div>
                                    </Link>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={'offscreen'}
                                whileInView={'onscreen'}
                                transition={{ y: { ease: 'easeIn' } }}
                                viewport={{ once: true }}
                                variants={pageTitleAnimation}
                                className='coaching-section'>
                                <img className="image-block" src={coachingBlock}
                                     alt=""/>
                                <div className='section-text'>
                                    <div className='section-title'>
                                        Resources
                                    </div>
                                    <div className='section-body'>
                                        Read a paper, take a class, or watch a video about stress reduction resources
                                    </div>
                                    <Link to="Resources">
                                        <div className='button-blocks'>
                                            <button className='learn-more-btn'>
                                                Learn More
                                            </button>
                                        </div>
                                    </Link>

                                </div>
                            </motion.div>
                        </div>
                    </div>
                    <div className='topic-sections-column'>
                        <div className='topic-sections-row'>
                            <motion.div
                                initial={'offscreen'}
                                whileInView={'onscreen'}
                                transition={{ y: { ease: 'easeIn' } }}
                                viewport={{ once: true }}
                                variants={pageTitleAnimation}
                                className='graduate-section'>
                                <img className="image-block" src={graduateBlock}
                                     alt=""/>
                                <div className='section-text'>
                                    <div className='section-title'>
                                        Contact Me
                                    </div>
                                    <div className='section-body'>
                                        Have any questions, concerns, or are interested in more information about me?
                                    </div>
                                    <Link to="Contact">
                                        <div className='button-blocks'>
                                            <button className='learn-more-btn'>
                                                Contact Me
                                            </button>
                                        </div>
                                    </Link>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={'offscreen'}
                                whileInView={'onscreen'}
                                transition={{ y: { ease: 'easeIn' } }}
                                viewport={{ once: true }}
                                variants={pageTitleAnimation}
                                className='publications-section'>
                                <img className="image-block" src={publicationsBlock}
                                     alt=""/>
                                <div className='section-text'>
                                    <div className='section-title'>
                                        Publications
                                    </div>
                                    <div className='section-body'>
                                        Read about my publications
                                    </div>
                                    <Link to="Media">
                                        <div className='button-blocks'>
                                            <button className='learn-more-btn'>
                                                Learn More
                                            </button>
                                        </div>
                                    </Link>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='image-side-panel-blocks'>
                <img className="book" src={bookSidePanel}
                     alt=""/>
            </div>
        </div>
    )
}

export default Blocks;
