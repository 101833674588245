import './therapyProfessionals.css'
import bookSidePanel from "../../assets/images/ripple.png";
import consultingBlock from "../../assets/images/consulting-block.png";
import coachingBlock from "../../assets/images/coaching-block.png";
import graduateBlock from "../../assets/images/graduate-block.png";
import publicationsBlock from "../../assets/images/publications-block.png";

const TherapyProfessionals = () => {
    return (
        <div className='therapy-professionals'>
            <div className='therapy-professionals-text-container'>
                <div className='therapy-professionals-title'>
                    Educating Therapy Professionals: The Ripple Effect
                </div>
                <div className='therapy-professionals-body'>
                    After decades of working with people as a manager and a Fortune 500 executive, then later as a
                    psychotherapist, I learned a thing or two about what works. I put that to the test in a
                    retrospective pilot study at the University of Arizona Integrative Health Center with significant
                    results.
                    <br/><br/>
                    As a Doctor of Behavioral Health, I was fired up to bring a broader understanding of the effects
                    of adversity and psychological trauma to healthcare providers - to teach how to help others heal
                    with bold new evidence-based therapies that enable people to go forward and have lives
                    well-lived.
                    <br/><br/>
                    In 2016, Cummings Graduate Institute invited me to do just that. To build a trauma-informed
                    specialty track within their 100% online Doctor of Behavioral Health degree program for the
                    healthcare leaders of the future.
                    <br/><br/>
                    The ripple effect in action.
                </div>
            </div>
            <div className='image-side-panel'>
                <img className="book" src={bookSidePanel}
                     alt=""/>
            </div>
        </div>
    )
}

export default TherapyProfessionals;
