import './testimonials.css'
import testimonialsImage from "../../assets/images/testimonials-image.png";

const Testimonials = () => {
    return (
        <div className='about-me'>
            <img className="about-me-image" src={testimonialsImage}
                 alt=""/>
            <div className='about-me-text-container'>
                <div className='about-me-title'>
                    Testimonials
                </div>
                <div className='about-me-body'>
                    <p className="testimonial-title-first">
                        “There are few practitioners I have worked with who I would label as superstars in
                        their field, Dr. Gayle Cordes is one of them. Her approach to counseling and
                        utilization of EMDR therapy has made profound changes in the lives of my patients.”
                    </p>
                    <p className="quote-by">
                        —Heidi Rula, MD, Medical Director, Supportive Care Services, Ironwood Cancer & Research Centers, and former Medical Director, University of Arizona
                        Integrative Health Center
                    </p>
                    <p className="testimonial-title">
                        “I have worked with Gayle since the early 90’s when she was a business executive in
                        the Detroit area. She is as dedicated and skilled in the therapy suite as she was in the
                        executive suite, now helping individuals rather than organizations heal, grow and
                        optimize their wellbeing.”
                    </p>
                    <p className="quote-by">
                        —Lewis W. Smith, PhD, Neuropsychological Consulting Services
                    </p>
                    <p className="testimonial-title">
                        "Dr. Cordes has served as the Trauma subject matter expert (SME) at CGI since we opened
                        our doors, and has spearheaded CGI’s trauma-informed curricula since day one. Thanks to
                        her leadership and dedication to the healthcare leaders of the future, CGI’s 100% online
                        graduate level training and education is first in the nation in designing and delivering
                        trauma-informed and evidence-based healthcare leaders, clinicians, environments, and
                        entrepreneurs. Our students love learning from her, and she is beloved by our faculty and
                        staff teams. We are very, very fortunate to have been the recipients of her time and SME
                        investments!"
                    </p>
                    <p className="quote-by">
                        —Dr. Cara English, CEO, Cummings Graduate Institute for Behavioral Health Studies
                    </p>
                    <p className="testimonial-title">
                        “I referred clients to Dr. Cordes for in excess of a decade. She helped them embrace and
                        transition into the substantial changes that divorce brings; my clients raved about the
                        assistance that she offered. Transitions are her superpower.”
                    </p>
                    <p className="quote-by">
                        —Catherine A. Creighton, Attorney at Law, MediateNOW
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;
