import './consultant.css'
import eyes from "../../assets/images/eyes.png";
import {Link} from "react-router-dom";

const Consultant = () => {

    return (
        <div id="working-together" className='consultant'>
            <img className="eyes" src={eyes}
                 alt=""/>
            <div className='consultant-text-container'>
                <div className='consultant-title'>
                    Working Together
                </div>
                <div className='consultant-body'>
                    A life well-lived is founded on what matters most to you. Whether you’re facing
                    work stress, a relationship issue, a health crisis or a life transition, I can help - by asking
                    questions, listening deeply, guiding you to see possibilities - then supporting you in
                    keeping your eye on what matters most to you. I have practical strategies in my toolkit
                    after many years in the corporate environment, the therapy suite, and the graduate
                    classroom. Together, we’ll create a way to get started and a process to help you move
                    forward.
                    <br/><br/>
                    As to how we meet, I work primarily by video conferencing and occasionally by telephone.
                </div>
                <Link to="Contact">
                    <div className='button'>
                        <button className='learn-more-btn'>
                            Contact Me
                        </button>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Consultant;
