import './aboutMePage.css'
import Header from "../header/Header";
import pathImage from "../../assets/images/path-image.jpg";
import Footer from "../footer/Footer";
import ImageSection from "../imageSection/ImageSection";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import React from "react";

const AboutMePage = () => {

    return (
        <div>
            <ScrollToTop />
            <Header/>
            <div className='media-blocks'>
                <div className='therapy-professionals-text-container'>
                    <div className='therapy-professionals-title'>
                        About Me
                    </div>
                    <div className='therapy-professionals-body'>
                        For nearly twenty years, I was a state-licensed psychotherapist and owner of an independent practice in Arizona, with specialty training and advanced certifications in trauma treatment therapies, including eye movement desensitization and reprocessing (EMDR). Along with my work in private practice, I served on staff at the 2012 launch of the University of Arizona Integrative Health Center in Phoenix, associated with Dr. Andrew Weil and the Arizona Center for Integrative Medicine, where I conducted an unpublished, retrospective study on the treatment effects of EMDR therapy on co-occurring anxiety and chronic medical conditions. Since 2018, I served on an EMDRIA Board task force charged with drafting guidelines for the delivery of EMDR therapy virtually as well as training therapists in EMDR therapy through distance learning. In 2022, that initiative developed into the inaugural EMDRIA Training Council, upon which I served as a member of the steering committee.
                        <br/><br/>
                        Prior to my career in psychotherapy, as an MBA, I spent 25 years in the corporate sector; in the later years of that chapter, I served within the executive ranks of a Fortune 500 company.
                        <br/><br/>
                        Since 2016, I have served on the graduate faculty of Cummings Institute for Behavioral Health Studies, where I developed a trauma specialty curriculum for the Doctor of Behavioral Health degree program. While I will always be passionate about the healing of psychological trauma and educating therapists, another favorite job of mine these days is being a grandma. To be near family, I relocated to Pennsylvania during the summer of 2022 where I continue to work virtually as a consultant, coach and educator.
                    </div>
                    <div className='therapy-professionals-title'>
                        Education
                    </div>
                    <div className='therapy-professionals-body'>
                        Arizona State University, Doctor of Behavioral Health, DBH <br/><br/>
                        University of Phoenix-Main Campus, MC <br/><br/>
                        Southern Illinois University-Edwardsville, MBA <br/><br/>
                        Millikin University, BA Psychology/English <br/><br/>
                    </div>
                    <div className='therapy-professionals-title'>
                        Other Training, Certifications & Achievements
                    </div>
                    <div className='therapy-professionals-body'>
                        Author of unpublished retrospective pilot study “EMDR in Integrative Primary Care:  Can It Improve Health Outcomes?” <br/><br/>
                        EMDR Certified Therapist, Consultant, Virtual Trainer <br/><br/>
                        Mindfulness Based Stress Reduction (MBSR) <br/><br/>
                        HeartMath <br/><br/>
                        Murray Method: Treating Trauma, Abuse, Neglect & Addiction <br/><br/>
                        Mind Design Hypnotherapy Training <br/><br/>
                        Dr. Kenneth Wapnick A Course In Miracles Academy Classes <br/><br/>
                        Dr. Stephen Covey 7 Habits Certification <br/><br/>
                        Dr. Paul Green Behavioral Interviewing<br/><br/>
                        Employee of the Year, Adia Personnel Services, U.S. Operations (1986, 1987)<br/><br/>
                        Area of the Year, Adia Personnel Services U.S. Operations (1984)<br/><br/>
                    </div>
                    <div className='therapy-professionals-title'>
                        Boards & Professional Affiliations
                    </div>
                    <div className='therapy-professionals-body'>
                        Health Restoration International - Board Member<br/><br/>
                        EMDR International - Training Council Steering Committee, Virtual Training & Therapy Task Group<br/><br/>
                        American Counseling Association<br/><br/>
                        National Board of Certified Counselors<br/><br/>
                        International Institute of Trauma & Addiction Professionals<br/><br/>
                        Grand Canyon Minority Supplier Development Council<br/><br/>
                        National Association of Female Executives<br/><br/>
                    </div>
                </div>
                <div className='image-side-panel'>
                    <img className="path-image" src={pathImage}
                         alt=""/>
                </div>
            </div>
            <ImageSection/>
            <Footer/>
        </div>
    )
}

export default AboutMePage;
