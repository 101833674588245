import './signUp.css'
import signUpImage from "../../assets/images/sign-up-image.png";
import {useState} from "react";

const SignUp = () => {
    const [validEmail, setValidEmail] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    })

    const handleFormChange = (e) => {
        console.log(e.target.name, e.target.value)
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const checkValidEmail = (e) => {
        const email = e.target.value
        const valid = email.includes('@') && email.includes('.')
        setValidEmail(valid || email === '')
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(formData)

        if (formData.email === '') {
            setValidEmail(false)
            return
        }

        if (validEmail) {
            // setModalOpen(true)
            await fetch(
                `https://us-central1-blueprint-llc.cloudfunctions.net/core/gayle`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                }
            )

            setSubmitted(true)

            setFormData({
                name: '',
                email: '',
                message: '',
            })
        }
    }

    return (
        <div className='sign-up'>
            <div className='sign-up-text-container'>
                <div className='sign-up-title'>
                    Contact Me
                </div>
                <div className='sign-up-body'>
                    Have any questions, concerns, or are interested in more information about me? Fill out this contact form and I will get back to you as soon as possible.
                </div>
                <div className='sign-up-button'>
                    <form action="" onSubmit={handleSubmit}>
                        <input className="text-field" type="text" id="name" name="name" placeholder="Name" value={formData.name} onChange={handleFormChange} autoComplete={'name'}/>
                        <input className="text-field"  type="email" id="email" name="email" onBlur={checkValidEmail} placeholder="Email" value={formData.email} onChange={handleFormChange}  autoComplete={'email'} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                        <textarea id="message" name="message" placeholder="Type your message here" rows={6} value={formData.message} onChange={handleFormChange} />
                        <button className='sign-up-btn' type="submit" value="Submit">
                            Submit
                        </button>
                    </form>
                    {submitted && <p className="submitted-message">Thank you for contacting me! I will get back to you as soon as possible.</p>}
                </div>
            </div>
            <img className="sign-up-image" src={signUpImage}
                 alt=""/>
        </div>
    )
}

export default SignUp;
