import './contactMe.css'
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SignUp from "../signUp/SignUp";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import React from 'react'

const ContactMe = () => {
    return (
        <div>
            <ScrollToTop />
            <Header/>
            <SignUp/>
            <Footer/>
        </div>
    )
}

export default ContactMe;
