import './resources.css'
import Header from "../header/Header";
import balanceImage from "../../assets/images/balance-image.png";
import Footer from "../footer/Footer";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import React from "react";

const Resources = () => {
    return (
        <div>
            <ScrollToTop />
            <Header/>
            <div className={"resources-container"}>
                <div className='therapy-professionals-text-container'>
                    <div className={"pub-container"}>
                        <div className='therapy-professionals-title'>
                            Stress Reduction Resources
                        </div>
                        <div className='resource-title'>
                            Dr. Gayle Cordes: Grounding Meditation for Moments of High Stress, Racing Thoughts, or Anxiety
                        </div>
                        <a href="https://cgi.edu/news/grounding-meditation/">
                            <div className='button'>
                                <button className='resource-button'>
                                    Listen to the Meditation
                                </button>
                            </div>
                        </a>
                        <div className='resource-title'>
                            CAPHealing: Chemical Assisted Psychotherapy
                        </div>
                        <a href="https://www.caphealing.com/">
                            <div className='button'>
                                <button className='resource-button'>
                                    Learn more
                                </button>
                            </div>
                        </a>
                        <div className='resource-title'>
                            UMASS Memorial Health Center for Mindfulness: MBSR Courses
                        </div>
                        <a href="https://www.ummhealth.org/center-mindfulness">
                            <div className='button'>
                                <button className='resource-button'>
                                    Take course
                                </button>
                            </div>
                        </a>
                        <div className='resource-title'>
                            Dr. Daniel Siegel’s Wheel of Awareness
                        </div>
                        <a href="https://drdansiegel.com/wheel-of-awareness">
                            <div className='button'>
                                <button className='resource-button'>
                                    Learn more
                                </button>
                            </div>
                        </a>
                        <a href="https://mindsightinstitute.com/">
                            <div className='button'>
                                <button className='resource-button'>
                                    Take course
                                </button>
                            </div>
                        </a>
                        <div className='resource-title'>
                            Joan Borysenko, PhD: Integrating Science & Spirituality for Your Best Health
                        </div>
                        <a href="https://joanborysenko.com/home/">
                            <div className='button'>
                                <button className='resource-button'>
                                    Read Paper
                                </button>
                            </div>
                        </a>
                        <div className='resource-title'>
                            Johns Hopkins Medicine: Health, Wellness and Prevention 9 Benefits of Yoga
                        </div>
                        <a href="https://www.hopkinsmedicine.org/health/wellness-and-prevention/9-benefits-of-yoga">
                            <div className='button'>
                                <button className='resource-button'>
                                    Read Paper
                                </button>
                            </div>
                        </a>
                        <div className='resource-title'>
                            Dr. Andrew Weil: The Famous 4-7-8 Breath
                        </div>
                        <a href="https://youtu.be/gz4G31LGyog">
                            <div className='button'>
                                <button className='resource-button'>
                                    Watch Video
                                </button>
                            </div>
                        </a>
                    </div>
                </div>
                <div className='image-side-panel'>
                    <img className="book" src={balanceImage}
                         alt=""/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Resources;
