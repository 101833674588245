import './hero.css'
import heroImage from "../../assets/images/hero-image.jpg"
import { motion } from 'framer-motion'

const Hero = () => {

    const pageTitleAnimation = {
        offscreen: {
            y: '20vh',
            opacity: 0,
        },
        onscreen: {
            y: 0,
            opacity: 0.85,
            transition: { duration: 0.9 },
        },
    }

    return (
        <div className="hero">
            <div className="hero-image-layer">
            </div>
                <img className="hero-image" src={heroImage}
                     alt="Person looking out at an island"/>
            <motion.div
                initial={'offscreen'}
                whileInView={'onscreen'}
                transition={{ y: { ease: 'easeIn' } }}
                viewport={{ once: true }}
                variants={pageTitleAnimation}
                className="hero-welcome">
                <div className="hero-welcome-text">
                    <div className="hero-welcome-title">
                        Welcome
                    </div>
                    <div className="title-line"/>
                </div>
                <div className="hero-welcome-body">
                    <div className="hero-welcome-body-text">
                        <p className="quote-body">
                            “Tell me, what is it you plan to do with your one wild and precious life?”
                        </p>
                        <p className={"quote-by"}>—Mary Oliver, The Summer Day</p>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default Hero;

